/* General Styling */
.item-list-container {
  margin-bottom: 20px;
}

.item-list-heading {
  font-size: 20px;
  text-align: center;
  padding: 10px 0;
  margin: 0;
}
/* ItemList.css */

/* ItemList.css */

/* ItemList.css */

/* Ensure the container has padding */
.item-list-container {
  padding: 5px; /* Optional: padding for the container */
}

/* Style for the Remove All button */
.remove-all-button {
  display: block; /* Make the button a block element to prevent inline behavior */
  margin: 0 auto; /* This centers the button horizontally */
  background-color: #f44336; /* Red color for the button */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px; /* Add margin to create space below the button */
}

.remove-all-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Adjusting padding for smaller devices */
@media (max-width: 600px) {
  .remove-all-button {
    padding: 8px 16px; /* Adjust button padding on smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

.remove-all-button.disabled {
  background-color: #666666; /* Grayed-out background */
  color: #b0b0b0; /* Grayed-out text */
  cursor: default; /* Change cursor to indicate it's not clickable */
}

.remove-all-button:disabled {
  opacity: 0.6; /* Optional: reduce opacity when disabled */
}

.item-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;
}

.item-card {
  font-size: 13px;
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-image {
  width: 100%;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 5px;
}

.item-info {
  flex: 1;
}

.button-container {
  display: flex;
  flex-direction: column;
  margin-top: auto; /* Pushes button container to the bottom */
}

.add-button,
.remove-button {
  font-size: 12px;
  padding: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  color: white;
}

.add-button {
  background-color: #8f8f8f;
}

.add-button.added {
  background-color: #4caf50;
}

.remove-button {
  background-color: #f44336;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .item-list-heading {
    font-size: 18px;
  }

  .item-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }

  .item-card {
    font-size: 12px;
  }

  .item-image {
    height: 60px;
  }

  .add-button,
  .remove-button {
    font-size: 10px;
  }
}
